import $ from "jquery";
import _ from "underscore";
import IsPassiveSupport from "../lib/IsPassiveSupport";

export default class spMenu {
  constructor() {
    this.pageContainer = document.getElementById('container');
    this.container = document.getElementsByClassName('js-spHeaderMenu')[0];
    this.scrollPos = 0;
    this.lockFlg = false;
    this.openFlg = false;
    this.init();
  }

  init() {
    this.setTabIndex();
    this.bindEvents();
  }

  setTabIndex() {
    // menu btn
    const menuBtnElm = document.getElementsByClassName('js-spMenuBtn')[0];
    if (menuBtnElm) {
      menuBtnElm.tabIndex = "0";
    }

    // close btn
    const closeElm = this.container.getElementsByClassName("spHeaderMenu__close")[0];
    if (closeElm) {
      const target = closeElm.getElementsByClassName("js-spHeaderMenuClose")[0];
      if (target) {
        target.tabIndex = "0";
      }
    }
  }

  bindEvents() {
    const self = this;
    const isPassive = new IsPassiveSupport();
    const menuBtnElm = document.getElementsByClassName('js-spMenuBtn')[0];
    const closeBtnElm = document.getElementsByClassName('js-spHeaderMenuClose');

    this.debouncedOnMenuClick = _.debounce(() => {
      if (this.lockFlg) return false;
      this.lockFlg = true;
      this.menuOpen();
    });

    if (menuBtnElm) {
      menuBtnElm.addEventListener("click", e => {
        e.preventDefault();
        e.stopPropagation();
        this.debouncedOnMenuClick();
      });
      menuBtnElm.addEventListener("keypress", e => {
        // Enter
        if (e.keyCode === 13) {
          this.debouncedOnMenuClick();
        }
      });
    }

    this.debouncedOnCloseBtnClick = _.debounce(() => {
      if (this.lockFlg) return false;
      this.lockFlg = true;
      this.menuClose();
    });

    if (closeBtnElm.length) {
      Array.prototype.forEach.call(closeBtnElm, elm => {
        elm.addEventListener("click", e => {
          e.preventDefault();
          e.stopPropagation();
          this.debouncedOnCloseBtnClick();
        });
        elm.addEventListener("keypress", e => {
          // Enter
          if (e.keyCode === 13) {
            this.debouncedOnCloseBtnClick();
          }
        });
      });
    }

    // resize event
    this.debouncedOnResize = _.debounce(() => {
      if (self.openFlg) {
        const wh = window.innerHeight ? window.innerHeight : $(window).height();
        this.container.style.height = wh + "px";
      }
    }, 600);
    window.addEventListener('resize', e => {
      this.debouncedOnResize();
    }, isPassive.check() ? { passive: true } : false);

    // change pc sp
    const handle = mq => {
      if (mq.matches) {
        // pc
        if (this.openFlg) {
          this.menuClose();
        }
      }
    }

    const mediaQuery = matchMedia('not all and (max-width: 767px)');
    handle(mediaQuery);
    mediaQuery.addListener(handle);
  }

  menuOpen() {
    this.openFlg = true;
    const wh = window.innerHeight ? window.innerHeight : $(window).height();
    this.scrollPos = window.pageYOffset || document.documentElement.scrollTop;
    this.container.style.height = wh + "px";
    this.container.classList.add('-visible');
    document.body.classList.add("-spMenuOpen");

    if (this.pageContainer) {
      this.pageContainer.style.top = - this.scrollPos + "px";
      this.pageContainer.style.height = window.innerHeight + this.scrollPos + "px";
    }

    _.delay(() => {
      const inner = this.container.getElementsByClassName("spHeaderMenu__inner")[0];
      if (inner) {
        inner.scrollTo(0, 0);
      }
      this.container.classList.add('-show');
      _.delay(() => {
        this.lockFlg = false;
      }, 360);
    }, 40);
  }

  menuClose() {
    this.container.classList.remove('-show');
    _.delay(() => {
      this.container.classList.remove('-visible');
      this.container.style.height = "";
      this.lockFlg = false;
      this.openFlg = false;
      document.body.classList.remove("-spMenuOpen");
      window.scrollTo(0, this.scrollPos);
      this.pageContainer.style.top = "";
      this.pageContainer.style.height = "";
    }, 500);
  }
}
