import $ from "jquery";
import spMenu from "./spMenu";
import header from "./Header";

$(() => {
  if (document.getElementsByClassName("js-header").length) {
    new header();
  }

  // spMenu
  if (document.getElementsByClassName("footer--simple").length) {
    document.getElementsByTagName('main')[0].classList.add('main--simple');
  }else if(document.getElementsByClassName("footer--mypage").length){
    document.getElementsByTagName('main')[0].classList.add('main--mypage');
  }
  if (document.getElementsByClassName("js-spHeaderMenu").length) {
    new spMenu();
  }
});
